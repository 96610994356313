import React, {useState, useEffect} from 'react';
import {useFilter, FilterStartingValues} from './useFilter';
import {
  IntermediateParsedPressRelease,
  paginationPageSize,
  PressRelease,
  PressReleaseListing,
  PressReleaseListingInternationalization,
} from './PressReleaseListing';
import {Icon} from './Icon';

function escapeRegex(string: string): string {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

function isNullOrWhitespace(input: string): boolean {
  return !input || !input.trim();
}

function currentAndLastThreeYears() {
  const currentYear = new Date().getFullYear();

  const years = [];

  for (let i = currentYear; i > currentYear - 4; i--) {
    years.push(i);
  }

  return years;
}

function transformPressRelease(
  pressRelease: IntermediateParsedPressRelease
): PressRelease {
  const prCategoryArr = pressRelease.category.split(':');

  let pressReleaseCategory: string;

  if (prCategoryArr.length < 2) {
    pressReleaseCategory = '';
  } else if (!isNullOrWhitespace(prCategoryArr[1])) {
    pressReleaseCategory = prCategoryArr[1].replace('-',' ');
  } else {
    pressReleaseCategory = prCategoryArr[0];
  }

  return {
    title: pressRelease.title,
    body: pressRelease.body,
    date: new Date(pressRelease.date),
    category: pressReleaseCategory,
    link: pressRelease.link,
    key: pressRelease.key,
  };
}

function shouldShowPressRelease(
  searchText: string,
  category: string | null,
  year: number | null,
  pressRelease: PressRelease
): boolean {
  if (year !== null && year !== pressRelease.date.getFullYear()) {
    return false;
  }

  if (
    !isNullOrWhitespace(searchText) &&
    pressRelease.title.match(RegExp(escapeRegex(searchText.trim()), 'gi')) ===
      null
  ) {
    return false;
  }

  if (
    category !== null &&
    pressRelease.category.match(RegExp(escapeRegex(category), 'gi')) === null
  ) {
    return false;
  }

  return true;
}

function preventSubmit(e: React.SyntheticEvent) {
  e.preventDefault();
}

interface FilterInternationalization {
  showingOfString: string;
  searchPlaceholder: string;
  yearLabel: string;
  searchLabel: string;
  categoryLabel: string;
  clearLabel: string;
  categoryCorporateLabel: string;
  categoryArchitecturalCoatingsLabel: string;
  categoryFinancialLabel: string;
  categoryInnovationLabel: string;
  categoryIndustrialLabel: string;
  categorySustainabilityLabel: string;
  categoryEmployeeLabel: string;
  categoryCultureLabel: string;
  categoryManufacturingAndDistributionLabel: string;
  categoryIndustrialCoatingsLabel: string;
  categoryLeadershipLabel: string;
  categoryStoresLabel: string;
  categoryInclusionDiversityEquityLabel: string;
  categoryCommunityEngagementLabel: string;
  categoryBuildingOurFutureLabel: string;
}

interface FilterProps {
  spritePath: string;
  filterStartingValues?: FilterStartingValues;
  prInternationalization?: PressReleaseListingInternationalization;
  filterInternationalization?: FilterInternationalization;
}

export function Filter({
  spritePath,
  filterStartingValues,
  prInternationalization = {
    noPrsFound: 'No press releases found.',
    noFilteredPrsFound:
      'No press releases found with the current filter settings.',
  },
  filterInternationalization = {
    showingOfString: 'Showing {current} of {total}',
    searchPlaceholder: 'Search...',
    yearLabel: 'Year',
    searchLabel: 'Search',
    categoryLabel: 'Category',
    clearLabel: 'Clear',
    categoryCorporateLabel: 'Corporate',
    categoryArchitecturalCoatingsLabel: 'Architectural Coatings',
    categoryFinancialLabel: 'Financial',
    categoryInnovationLabel: 'Innovation',
    categoryIndustrialLabel: 'Industrial',
    categorySustainabilityLabel: 'Sustainability',
    categoryEmployeeLabel: 'Employee',
    categoryCultureLabel: 'Culture',
    categoryManufacturingAndDistributionLabel: 'Manufacturing & Distribution',
    categoryIndustrialCoatingsLabel: 'Industrial Coatings',
    categoryLeadershipLabel: 'Leadership',
    categoryStoresLabel: 'Stores',
    categoryInclusionDiversityEquityLabel: 'Inclusion, Diversity & Equity',
    categoryCommunityEngagementLabel: 'Community & Engagement',
    categoryBuildingOurFutureLabel: 'Building Our Future',
  },
}: FilterProps) {
  const filter = useFilter(filterStartingValues);
  const [searchText, setSearchText, category, setCategory, year, setYear] =
    filter;
  const [pressReleases, setPressReleases] = useState<PressRelease[]>([]);
  const [filteredPressReleases, setFilteredPressReleases] = useState<
    PressRelease[]
  >([]);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    const jsonScript = document.getElementById('pressReleaseJsonOutput');

    if (jsonScript === null) {
      return;
    }

    let parsedPressReleases: IntermediateParsedPressRelease[];

    try {
      parsedPressReleases = JSON.parse(jsonScript.innerHTML);
    } catch (error) {
      console.error(error);
      return;
    }

    if (!Array.isArray(parsedPressReleases)) {
      return;
    }

    const parsedPrs = parsedPressReleases.map(transformPressRelease);

    setPressReleases(parsedPrs);

    const filteredPrs = parsedPrs.filter((pressRelease: PressRelease) =>
      shouldShowPressRelease(searchText, category, year, pressRelease)
    );

    setFilteredPressReleases(filteredPrs);
  }, []);

  useEffect(() => {
    const filteredPrs = pressReleases.filter((pressRelease: PressRelease) =>
      shouldShowPressRelease(searchText, category, year, pressRelease)
    );

    setFilteredPressReleases(filteredPrs);
  }, [searchText, category, year, pressReleases, pageNumber]);

  function resetPageNumber() {
    setPageNumber(0);
  }

  function updateSearchText(event) {
    setSearchText(event.target.value);
    resetPageNumber();
  }

  function updateCategory(event) {
    const eventValue = event.target.value;

    setCategory(eventValue === 'none' ? null : eventValue);
    resetPageNumber();
  }

  function updateYear(event) {
    const parsedYear = parseInt(event.target.value, 10);

    setYear(isNaN(parsedYear) ? null : parsedYear);
    resetPageNumber();
  }

  function resetFilter() {
    setSearchText('');
    setCategory(null);
    setYear(null);
    resetPageNumber();
  }

  const dropDownIcon = (
    <Icon
      spritePath={spritePath}
      iconName={'filter-dropdown-arrow'}
      iconClassName={'cmp-pressreleaselisting__filter-form-arrow'}
    />
  );

  const firstIndexToTake = pageNumber * paginationPageSize;

  return (
    <div className="cmp-pressreleaselisting">
      <div className="cmp-pressreleaselisting__counter">
        {filterInternationalization.showingOfString
          .replace(
            '{current}',
            Math.min(
              paginationPageSize,
              filteredPressReleases.length - firstIndexToTake
            ).toString()
          )
          .replace('{total}', pressReleases.length)}
      </div>
      <div className="cmp-pressreleaselisting__content">
        <form
          className="cmp-pressreleaselisting__filter-form"
          onSubmit={preventSubmit}
          onReset={resetFilter}
        >
          <div className="cmp-pressreleaselisting__filter-form-element-with-icon-container">
            <input
              className="cmp-pressreleaselisting__filter-form-element"
              onChange={updateSearchText}
              value={searchText}
              placeholder={filterInternationalization.searchPlaceholder}
              aria-label={filterInternationalization.searchLabel}
            />
            <Icon
              spritePath={spritePath}
              iconName={'filter-search'}
              iconClassName={'cmp-pressreleaselisting__filter-form-search'}
            />
          </div>
          <div className="cmp-pressreleaselisting__filter-form-element-with-icon-container">
            <select
              className="cmp-pressreleaselisting__filter-form-element"
              onChange={updateCategory}
              value={category === null ? 'none' : category}
              aria-label={filterInternationalization.categoryLabel}
            >
              <option value="none">
                {filterInternationalization.categoryLabel}
              </option>
              <option value="corporate">
                {filterInternationalization.categoryCorporateLabel}
              </option>
              <option value="architectural-coatings">
                {filterInternationalization.categoryArchitecturalCoatingsLabel}
              </option>
              <option value="financial">
                {filterInternationalization.categoryFinancialLabel}
              </option>
              <option value="innovation">
                {filterInternationalization.categoryInnovationLabel}
              </option>
              <option value="industrial">
                {filterInternationalization.categoryIndustrialLabel}
              </option>
              <option value="sustainability">
                {filterInternationalization.categorySustainabilityLabel}
              </option>
              <option value="employee">
                {filterInternationalization.categoryEmployeeLabel}
              </option>
              <option value="culture">
                {filterInternationalization.categoryCultureLabel}
              </option>
              <option value="manufacturing-&-distribution">
                {
                  filterInternationalization.categoryManufacturingAndDistributionLabel
                }
              </option>
              <option value="industrial-coatings">
                {filterInternationalization.categoryIndustrialCoatingsLabel}
              </option>
              <option value="leadership">
                {filterInternationalization.categoryLeadershipLabel}
              </option>
              <option value="stores">
                {filterInternationalization.categoryStoresLabel}
              </option>
              <option value="inclusion,-diversity-&-equity">
                {
                  filterInternationalization.categoryInclusionDiversityEquityLabel
                }
              </option>
              <option value="community-engagement">
                {filterInternationalization.categoryCommunityEngagementLabel}
              </option>
              <option value="building-our-future">
                {filterInternationalization.categoryBuildingOurFutureLabel}
              </option>
            </select>
            {dropDownIcon}
          </div>
          <div className="cmp-pressreleaselisting__filter-form-element-with-icon-container">
            <select
              className="cmp-pressreleaselisting__filter-form-element"
              onChange={updateYear}
              value={year === null ? 'None' : year.toString()}
              aria-label={filterInternationalization.yearLabel}
            >
              <option value="None">
                {filterInternationalization.yearLabel}
              </option>
              {currentAndLastThreeYears().map((prYear) => {
                const prYearString = prYear.toString();
                return (
                  <option key={prYearString} value={prYearString}>
                    {prYearString}
                  </option>
                );
              })}
            </select>
            {dropDownIcon}
          </div>
          <button
            className="cmp-pressreleaselisting__filter-form-clear"
            type="reset"
          >
            {filterInternationalization.clearLabel}
          </button>
        </form>
        <PressReleaseListing
          searchText={searchText}
          category={category}
          year={year}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pressReleases={pressReleases}
          filteredPressReleases={filteredPressReleases}
          i18nMessages={prInternationalization}
          spritePath={spritePath}
        />
      </div>
    </div>
  );
}
