import React from 'react';
import ReactDOM from 'react-dom';

import {Filter} from './Filter';

const reactElem = document.getElementById('pressReleaseReact');

const spritePath = reactElem.dataset.spriteSheetPath;

ReactDOM.render(
  <React.StrictMode>
    <Filter spritePath={spritePath} />
  </React.StrictMode>,
  reactElem
);
