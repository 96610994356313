import React, {useMemo} from 'react';

import Pagination from '../pagination/pagination';
import {Icon} from './Icon';

export interface IntermediateParsedPressRelease {
  title: string;
  body: string;
  date: string;
  category: string;
  link: string;
  key: string;
}

export interface PressRelease {
  title: string;
  body: string;
  date: Date;
  category: string;
  link: string;
  key: string;
}

const pressReleaseDateFormattingOptions: Intl.DateTimeFormatOptions = {
  month: 'long',
  year: 'numeric',
  day: 'numeric',
};

export const paginationPageSize = 12;

interface PressReleaseListingProps {
  searchText: string;
  category: string | null;
  year: number | null;
  pageNumber: number;
  setPageNumber: (n: number) => void;
  pressReleases: PressRelease[];
  filteredPressReleases: PressRelease[];
  i18nMessages: PressReleaseListingInternationalization;
  spritePath: string;
}

export interface PressReleaseListingInternationalization {
  noPrsFound: string;
  noFilteredPrsFound: string;
}

export function PressReleaseListing({
  searchText,
  category,
  year,
  pageNumber,
  setPageNumber,
  pressReleases,
  filteredPressReleases,
  i18nMessages,
  spritePath,
}: PressReleaseListingProps) {
  const pressReleaseListingHtml = useMemo(() => {
    if (pressReleases.length === 0) {
      return <p>{i18nMessages.noPrsFound}</p>;
    }

    if (filteredPressReleases.length === 0) {
      return <p>{i18nMessages.noFilteredPrsFound}</p>;
    }

    const firstIndexToTake = pageNumber * paginationPageSize;

    return filteredPressReleases
      .map((pressRelease: PressRelease) => {
        return (
          <article
            className="cmp-pressreleaselisting__article"
            key={pressRelease.key}
          >
            <p className="cmp-pressreleaselisting__category">
              {pressRelease.category}
            </p>
            <p className="cmp-pressreleaselisting__date">
              {pressRelease.date.toLocaleDateString(
                'en-US',
                pressReleaseDateFormattingOptions
              )}
            </p>
            <a
              href={pressRelease.link + '.html'}
              className="cmp-pressreleaselisting__link"
            >
              <h3 className="cmp-pressreleaselisting__title">
                {pressRelease.title}{' '}
                <Icon
                  spritePath={spritePath}
                  iconName={'filter-arrow-right'}
                  iconClassName={'cmp-pressreleaselisting__title-arrow'}
                />
              </h3>
            </a>
          </article>
        );
      })
      .slice(firstIndexToTake, firstIndexToTake + paginationPageSize);
  }, [searchText, category, year, pressReleases, filteredPressReleases]);

  return (
    <div className="cmp-pressreleaselisting__container">
      {pressReleaseListingHtml}
      <Pagination
        setPage={setPageNumber}
        currentPage={pageNumber}
        numberOfItems={filteredPressReleases.length}
        pageSize={paginationPageSize}
      />
    </div>
  );
}
