import React, {useMemo} from 'react';
import './pagination.site.scss';

function Pagination({
  currentPage = 0,
  pageSize = 10,
  numberOfItems = 0,
  i18nLabel = 'Goto Page ${page}',
  setPage,
}) {
  const pages = useMemo(
    () => new Array(Math.ceil(numberOfItems / pageSize) || 1).fill(0),
    [numberOfItems, pageSize]
  );

  return (
    <nav className="sw-pagination" role="navigation">
      <ol className="sw-pagination__list">
        {pages.map((_, i: number) => {
          const page = '' + (i + 1);

          return (
            <li key={i}>
              <button
                aria-label={i18nLabel.replace('${page}', page)}
                className="sw-pagination__btn"
                onClick={setPage && setPage.bind(null, i)}
                type="button"
                disabled={i == currentPage}
              >
                {page}
              </button>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

export default Pagination;
